*{
  padding: 0;
  margin: 0;
}

.container{
  display: grid;
  position: relative;
  height: 100vh;
  grid-template-columns: 220px auto;
}
::-webkit-scrollbar {
  width: 3px;
}

body{
  font-family: 'Poppins';
}

.login_container{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Form{
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(128, 128, 128, 0.226);
  width: 30rem;
  /* height: 60%; */
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  background: white;
}

.login_head{
  font-size: 2vh;
  color: rgba(0, 0, 0, 0.838);
  text-align: center;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.login_head span{
  font-size: 3vh;
  font-weight: 500;
  letter-spacing: 1px;
}
.inputF{
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5vh;
}

.inputF label{
  font-weight: 600;
  margin-bottom: .4vh;
}
.inputF input, .inputF select{
  outline: none;
  border: 1px solid #363636;
  padding: 0.6rem 0.5rem;
  border-radius: 4px;
  font-size: 1rem;
}

.Form button{
  margin: 1rem 0;
  cursor: pointer;
  border: 1px solid #363636;
  background-color: #363636;
  color: #fff;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 0.5rem;
  transition: 300ms all ease;
}

.Form  button:hover{
  background-color: #fff;
  color: #363636;
  font-weight: 500;
}
















.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}


.css-1ygcj2i-MuiTableCell-root{
  padding: 0.5rem 0 !important;
 
}
.css-1ygcj2i-MuiTableCell-root span{
  /* font-weight: bold !important; */
  text-transform: capitalize;
  font-size: 0.95rem;
}

.subscription-modal{
  background-color: rgba(172, 172, 172, 0.377);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.subscription-modal .subs-container{
  background-color: white;
  width: fit-content;
  min-width: 25%;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 2px rgba(101, 101, 101, 0.6);
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.subscription-modal .subs-container > section{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.subscription-modal .subs-container nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#close-icon{
  font-size: 1.6rem;
  color: rgb(249, 42, 42);
  border-radius: 50%;
  padding: 0.2rem;
}
#close-icon:hover{
  cursor: pointer;
  background-color: rgba(182, 182, 182, 0.217);
  transition: 0.6s;
}
.primary-btn{
    text-decoration: none;
    background: #4863f8;
    font-size: 13px;
    padding: 0.7rem 10px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    outline: none;
    border: 1px solid #4863f8;
    cursor: pointer;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 200ms all ease-in;
  }
.primary-btn:hover{
  background: #fff;
  color: #7c73e6;
}
.input-field{
  
  outline: none;
  border: .5px solid grey;
  border-radius: 4px;
  padding: .9vh .5vw;
}