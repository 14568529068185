.topbar{
    /* height: 5vh; */
    width: 100vw;
    position: sticky;
    top:0;
    background: #fff;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.103) 0px 1px 4px;
}
.topbarWraper{
    height: 100%;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo{
    font-weight: bold;
    font-size: 26px;
    color: rgb(226, 92, 92);
    cursor: pointer;
}

.topRight{
    display: flex;
    align-items: center;
}

.language{
    outline: none;
    width: 120px;
    margin-right: 1vw;
    margin-top: .5vh;

}
.language select{
    width: 100%;
    outline: none;
    cursor: pointer;
    padding: 2px 7px;
    border: 1px solid rgba(172, 169, 169, 0.514);
    color: rgba(114, 114, 114, 0.863);
    border-radius: 3px;
}

.topbarIconContainer{
    width: 1vw;
    height: 2vh;
    cursor: pointer;
    margin-right: 1vw;
    color: rgb(116, 116, 116);
    border-radius: 50%;
    text-align: center;
    padding: .4vh;
    font-size: 2vh;
}

.topbarIconContainer:hover{
    background-color: rgba(151, 155, 155, 0.534);
    color: rgb(250, 123, 123);
}

.topAvatar{
    width: 2vw;
    height: 4vh;
    border-radius: 50%;
    cursor: pointer;
}