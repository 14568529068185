.sidebar {
  /* width: 220px; */
  height: 100%;
  background-color: rgb(253, 253, 253);
  /* border: 1px solid red; */
  box-shadow: 5px 5px 5px #3636361a;
}
.sidebarWraper {
  height: 90%;
  padding: 0.2vh .9vw;
  color: #555;
  position: fixed;
  overflow-y: auto;
}

.sidebarTitle {
  font-size: 10px;
  color: rgba(165, 165, 165, 0.774);
  font-weight: 500;
  letter-spacing: 1px;
}

.sidebarList {
  list-style: none;
  padding-left: 5px;
}
.sidebarItem {
  padding: 5px 5px;
  margin: 1px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 0.5vw;
  width: 100%;
  font-size: 1.4vh;
  transition: 200ms all ease-in;
}
.sidebarItem:hover{
  background: #3636361a;
  
}
.sidebarItem.active{
  background-color: rgb(228, 228, 250);
  font-weight: 500;
  color:#CE7777;
  padding: 5px 15px;
}
.sidebarList a {
  text-decoration: none;
  color: #555;
  display: flex;
  
  align-items: center;
}

.sidebarItem svg {
  font-size: 1vw !important;
}
.sidebarIcon {
  margin-right: 0.5vw;
  font-size: 2vh !important;
}
