body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tablsUSER{
  width: 100%;
  overflow-x: auto;
}

.tablsUSER ::-webkit-scrollbar {
  width: 0px;
  height: 10px;
  /* border-radius: 20px; */
  cursor: pointer;
}
.tablsUSER ::-webkit-scrollbar-track {
  background: #c4c0c0b2;
}

.tablsUSER ::-webkit-scrollbar-thumb {
  background: rgba(255, 89, 89, 0.527);
}
.tablsUSER ::-webkit-scrollbar-thumb:hover {
  background: #d32d2d;
}

.popup {
  position: relative;
  display: flex;
  flex-direction: column;
  top: -65%;
  left: 75%;
  width: 18%;
  height: 35%;
  background-color: rgba(0, 0, 0, 0.411);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(99, 99, 99, 0.507) 0px 2px 8px 0px;
  border-radius: 2%;
  /* transition: 1000ms all ease; */
}

.popup-content {
  width: 90%;
  height: 70%;
  gap: 5px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  /* margin: 20px 15px; */
  /* padding-top: 10px; */
}

.popup-content input{
  outline: none;
  padding: 6px 10px;
  cursor: pointer;
  border: 1px solid rgba(128, 128, 128, 0.507);
  border-radius: 10px;
  font-size: 16px;
}
.popup-content .lable{
  margin-top: 10px;
}

.fillBtn{
  margin-top: 2rem;
  width: 90%;
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  background-color: rgba(0, 0, 0, 0.788);
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  transition: all 500ms ease;
}

.fillBtn:hover{
  color:rgba(0, 0, 0, 0.788);
  background-color: #fff;
}

.succ{
  background-color: rgba(13, 192, 13, 0.815);
  color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
  font-weight: 600;
}
.fail{
  background-color: rgba(233, 42, 28, 0.801);
  color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
  font-weight: 600;
}