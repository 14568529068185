.MainContainer
    // border: 1px solid red
    // width: 100%
    margin: 20px
    // background: red
    // width: 80vw
    overflow-x: auto
    .breadcrumb
        display: flex
        justify-content: right
        margin-bottom: 1rem
        span
            display: flex
            align-items: center
            font-weight: 500
            color: #363636
            a
                text-decoration: none
                margin: 0 0.2rem
                gap: 0.1rem
                color: #363636
                display: flex
                align-items: center
                font-weight: 500
                font-size: 13px
                span
                    font-size: 16px
    .Header
        box-shadow: 0px 0px 5px #36363636
        padding: 0.7rem
        border-radius: 3px
        margin: 1rem 0
        display: flex
        align-items: center
        justify-content: space-between
        a
            background: #fff
            border: #363636 1px solid
            text-decoration: none
            padding: 0.4rem 0.6rem
            cursor: pointer
            color: #363636
            font-weight: 500
            border-radius: 4px
            font-size: 0.9rem
            transition: 300ms all ease
            &:hover
                background-color: #363636
                color: #fff
        h3
            text-transform: uppercase
            display: flex
            align-items: flex-end
            justify-content: left
            gap: 0.4rem
            color: #363636
            span
                font-size: 1.6rem
        div
            display: flex
            align-items: center
            gap: 10px

.MainContainer
    .AddHead
        display: flex
        justify-content: space-between
        align-items: center
        span
            display: flex
            align-items: center
            font-weight: 500
            color: #363636
            a
                text-decoration: none
                margin: 0 0.1rem
                font-size: 2.2vh
                gap: 0.2rem
                color: #363636
                display: flex
                align-items: center
                font-weight: 500
                span
                    font-size: 1rem
    .Box_Container
        width: 100%
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px
        // overflow-y: auto
        form
            // width: 90%
            padding: 30px
            display: flex
            flex-direction: column
            // margin: 5vh 0vw
            gap: 2vw
            span
                text-align: center
                font-size: 14px
            .InputF
                display: flex
                gap: 2vw
                color: #1b243098

            .inputField_type_one
                // margin-top: 1vh
                display: flex
                flex-direction: column
                justify-content: start
                gap: 5px
                color: #1b243098
                label
                    font-size: 14px
                    margin-top: 1vh
                    font-weight: 500
                input
                    width: 20vw
                    // margin-top: vh
                    outline: none
                    padding: 1vh .5vw
                    border: .5px solid grey
                    border-radius: 4px
                    &::-webkit-outer-spin-button
                        -webkit-appearance: none
                        margin: 0
                    &::-webkit-inner-spin-button
                        -webkit-appearance: none
                        margin: 0
                select
                    // width: 20vw
                    // margin-top: 1vh
                    outline: none
                    border: .5px solid grey
                    border-radius: 4px
                    padding: .9vh .5vw
                textarea
                    outline: none
                    padding: 1vh .5vw
                    border: .5px solid grey
                    border-radius: 4px
            .decription
                display: flex
                flex-direction: column
                width: 44vw
                color: #1b243098
                label
                    margin-bottom: 5px
                    font-weight: 500
                    color: #1b243098
                textarea
                    padding: 10px
                    outline: none
            .isActive
                display: flex
                flex-direction: column
                justify-content: start
                gap: 5px
                color: #1b243098
                label
                    font-size: 14px
                    margin-top: 1vh
                    font-weight: 500
                input
                    width: 20vw
                    // margin-top: vh
                    outline: none
                    padding: 1vh .5vw
                    border: .5px solid grey
                    border-radius: 4px
                    &::-webkit-outer-spin-button
                        -webkit-appearance: none
                        margin: 0
                    &::-webkit-inner-spin-button
                        -webkit-appearance: none
                        margin: 0
                select
                    width: 21vw
                    // margin-top: 1vh
                    outline: none
                    border: .5px solid grey
                    border-radius: 4px
                    padding: .9vh .5vw
                textarea
                    outline: none
                    padding: 1vh .5vw
                    border: .5px solid grey
                    border-radius: 4px
            .subInputF
                color: #1b243098
                margin-top: 1rem
                span
                    font-size: 16px
                    font-weight: 500
                .DietInPutF
                    display: flex
                    align-items: center
                    gap: 20px
                    .dietType
                        display: flex
                        gap: 40px
                        // border: 1px solid red
                        margin-bottom: 2rem
                        .mealTitles
                            display: flex
                            flex-direction: column
                            gap: 5px
                            label
                                font-size: 14px
                                margin-top: 1vh
                                font-weight: 500
                            .descEn
                                max-width: 800px
                                margin-bottom: 1rem
                        .mealTitles2
                            display: flex
                            flex-direction: column
                            gap: 5px
                            label
                                font-size: 14px
                                margin-top: 1vh
                                font-weight: 500
                            textarea
                                width: 21vw
                                outline: none
                                padding: .5vh .5vw
                                border: .5px solid grey
                                border-radius: 4px
                    .dietType2
                        display: flex
                        flex-direction: column
                        gap: 5px
                        label
                            font-size: 14px
                            margin-top: 1.5vh
                            font-weight: 500
                        textarea
                            width: 41vw
                            // margin-top: vh
                            outline: none
                            padding: 1vh .5vw
                            border: .5px solid grey
                            border-radius: 4px
                    .delete
                        font-size: 1.5rem
                        margin-top: 3.5vh
                        cursor: pointer
                        &:hover
                            color: red
                .addBTn
                    width: 4vw
                    margin-top: 1rem
                    background: #fff
                    border: #363636 1px solid
                    text-decoration: none
                    padding: 0.4rem 0.6rem
                    cursor: pointer
                    color: #363636
                    font-weight: 500
                    border-radius: 4px
                    font-size: 0.9rem
                    transition: 300ms all ease
                    &:hover
                        background-color: #363636
                        color: #fff
            .btn
                display: flex
                justify-content: right
                button
                    display: flex
                    align-items: center
                    justify-content: space-between
                    margin-left: 1vw
                    background: #fff
                    border: #363636 1px solid
                    text-decoration: none
                    padding: 0.4rem 0.6rem
                    cursor: pointer
                    color: #363636
                    font-weight: 500
                    border-radius: 4px
                    font-size: 0.9rem
                    transition: 300ms all ease
                    &:hover
                        background-color: #363636
                        color: #fff
                    span
                        margin-right: 2px
                        font-size: 20px
                a
                    text-decoration: none
                    display: flex
                    margin-left: 1vw
                    background: #fff
                    border: #363636 1px solid
                    text-decoration: none
                    padding: 0.4rem 0.6rem
                    cursor: pointer
                    color: #363636
                    font-weight: 500
                    border-radius: 4px
                    font-size: 0.9rem
                    transition: 300ms all ease
                    &:hover
                        background-color: #363636
                        color: #fff

.editBTN
    text-decoration: none
    button
        background: #7c73e6
        font-size: 13px
        padding: 5px 10px
        font-weight: 500
        color: #fff
        text-transform: uppercase
        outline: none
        border: 1px solid #7c73e6
        cursor: pointer
        letter-spacing: 1px
        border-radius: 3px
        transition: 200ms all ease-in
        &:hover
            background: #fff
            color: #7c73e6
.userDetailBtn
    text-decoration: none
    button
        background: #4863f8
        font-size: 13px
        padding: 5px 10px
        font-weight: 500
        color: #fff
        text-transform: uppercase
        outline: none
        border: 1px solid #4863f8
        cursor: pointer
        letter-spacing: 1px
        border-radius: 3px
        transition: 200ms all ease-in
        &:hover
            background: #fff
            color: #7c73e6
.deleteBTN
    text-decoration: none
    button
        background: #e67373
        font-size: 13px
        padding: 5px 10px
        font-weight: 500
        color: #fff
        text-transform: uppercase
        outline: none
        border: 1px solid #e67373
        cursor: pointer
        letter-spacing: 1px
        border-radius: 3px
        transition: 200ms all ease-in
        &:hover
            background: #fff
            color: #e67373
.MediaUploads
    display: flex
    gap: 60px

.imgUpload
    // border: 1px solid #7c73e6
    width: 400px
    display: flex
    flex-direction: column
    gap: 20px
    .imgContainer
        height: 200px
        border: 1px solid #36363656
        border-radius: 7px
        box-shadow: inset 0px 0px 7px #36363656
        display: flex
        align-items: center
        justify-content: center
        color: #3636365d
        overflow: hidden
        padding: 10px
        img
            height: 100%
    input
        display: none
    label
        border: 1px solid #363636
        background: #fff
        width: 120px
        text-align: center
        border-radius: 3px
        color: #363636
        cursor: pointer
        padding: 5px 10px
        font-size: 15px
        transition: 200ms all ease
        &:hover
            background: #363636
            color: #fff

.imgUploadMulti
    display: flex
    flex-direction: column
    gap: 20px
    .imgContainerMulti
        position: relative
        border: 1px solid #36363656
        box-shadow: inset 0px 0px 7px #36363656
        height: 200px
        width: 800px
        border-radius: 5px
        display: flex
        gap: 20px
        justify-content: left
        flex-direction: row
        align-items: center
        overflow-x: scroll
        padding: 10px
        &::-webkit-scrollbar
            // border: 1px solid red
            height: 10px
            cursor: pointer
            box-shadow: inset 0px 0px 3px #36363646
        &::-webkit-scrollbar-thumb
            border-radius: 3px
            background: #3636366c
            cursor: pointer
        p
            // position: absolute
            left: 0
            right: 0
            margin: auto
        .imgBlock
            position: relative
            height: 100%
            display: flex
            flex-direction: column
            gap: 4px
            img
                height: 87%
                margin-top: 4px
            svg
                position: absolute
                cursor: pointer
                top: -10px
                right: -14px
                font-size: 18px
                &:hover
                    background: #36363625
                    border-radius: 50%  
            input
                display: inline
                outline: none
                height: 1vh
                width: 80px
                padding: 8px
                box-shadow: inset 0px 0px 3px #36363646
                border: none
                border-radius: 5px
                &::-webkit-outer-spin-button
                    -webkit-appearance: none
                    margin: 0
                &::-webkit-inner-spin-button
                    -webkit-appearance: none
                    margin: 0
                    
                    
    input
        display: none
    label
        border: 1px solid #363636
        background: #fff
        width: 120px
        text-align: center
        border-radius: 3px
        color: #363636
        cursor: pointer
        padding: 5px 10px
        font-size: 15px
        transition: 200ms all ease
        &:hover
            background: #363636
            color: #fff
.tableIMG
    height: 3.5vw
    img
        width: 6vw
        height: 100%
        border-radius: 10px 
.tableIMG1
    height: 3.5vw
    img
        width: 3.8vw
        height: 100%
        border-radius: 50% 


.multiOptions
    // border: 1px solid red
    margin-bottom: 20px
    // width: 600px
    display: grid
    grid-template-columns: 1fr 1fr 1fr 1fr
    gap: 20px
    .checkBOX
        // border: 1px solid red
        cursor: pointer
        display: flex
        justify-items: left
        align-items: center
        gap: 5px
        input
            width: auto !important
            cursor: pointer
        label
            margin: 0 !important
            text-transform: uppercase
            cursor: pointer


.Editoe_form
    width: 100%
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px
    form
        padding: 30px
        display: flex
        flex-direction: column
            // margin: 5vh 0vw
        gap: 2vw
        .terms
            label
                font-size: 18px
                font-weight: 500
                color: grey
            .terms_data
                margin: 2vh 0
                .quill
                     height: 18rem
        .btn
                display: flex
                justify-content: right
                button
                    display: flex
                    align-items: center
                    justify-content: space-between
                    margin-left: 1vw
                    background: #fff
                    border: #363636 1px solid
                    text-decoration: none
                    padding: 0.4rem 0.6rem
                    cursor: pointer
                    color: #363636
                    font-weight: 500
                    border-radius: 4px
                    font-size: 0.9rem
                    transition: 300ms all ease
                    &:hover
                        background-color: #363636
                        color: #fff
                    span
                        margin-right: 2px
                        font-size: 20px
                a
                    text-decoration: none
                    display: flex
                    margin-left: 1vw
                    background: #fff
                    border: #363636 1px solid
                    text-decoration: none
                    padding: 0.4rem 0.6rem
                    cursor: pointer
                    color: #363636
                    font-weight: 500
                    border-radius: 4px
                    font-size: 0.9rem
                    transition: 300ms all ease
                    &:hover
                        background-color: #363636
                        color: #fff

.userInfo
    gap: 10px
    padding: 20px
    .user_container
        // border : 1px solid #fd3f3f
        display: flex
        margin: 10px 0px
        box-shadow: 0px 0px 1px #181817
        padding: 10px 20px
        border-radius: 3px
        gap: 60px
        img
            width: 200px
            border-radius: 50%
    .basicInfo
        display: flex
        width: 40%
        flex-direction: column
        h4
            padding: 0px auto
            font-size: 18px
            font-weight: 600
            text-align: center
        .userDetail
            display: flex
            justify-content: space-between
            gap: 20px
            padding: 5px 0px
            span
                font-weight: 500
                color: #535353
            p
                margin-left: 10px
    hr
        margin: 15px 0px
        border: 2px solid #afafaf4d
        border-radius: 50px

.userSubscripton
    width: 100%
    display: flex
    flex-direction: column
    // border : 1px solid #fd3f3f
    .head
        margin: 10px auto
        font-size: 18px
        font-weight: 600
        color: #20202067

.user_container1
    h1
        // margin: 10px auto
        text-align: center
        font-size: 18px
        font-weight: 600
        color: #20202067
        // border: 1px solid red

.react-responsive-modal-modal
    width: 26%
    border-radius: 3px
.messageModal
    display: flex
    flex-direction: column
    // border : 1px solid #fd3f3f 
    gap: 15px
    h4
        text-align: center
    input
        outline: none
        padding: 8px
        // border-radius: 3px
    textarea
        outline: none
        padding: 8px
        border-radius: 3px
    .btn
        display: flex
        justify-content: flex-end
        gap: 20px
        button
            padding: 7px 15px
            border: none
            cursor: pointer
            font-weight: 500
            text-transform: uppercase
            border-radius: 3px
            
        .cancel
            background-color: #c70000a1
            color: #fff
            &:hover
                background-color: #d61f1fef
        .send
            background-color: #226801a1
            color: #fff
            &:hover
                background-color: #08a822ef

.selected
    select
        width: 8vw
        outline: none
        border-radius: 4px
        padding: 8px

.select
    width: 20vw
    outline: none
    border-radius: 4px
     padding: 8px
.videoURL
    display: flex
    .addVideo
        width:45vw
        display: flex
        flex-direction: column
        gap: 20px
        input
            outline: none
            padding: 10px
        span
            width: 140px
            border: 1px solid #363636
            background: #fff
            text-align: center
            border-radius: 3px
            color: #363636
            cursor: pointer
            padding: 7px 10px
            font-size: 15px
            transition: 200ms all ease
            &:hover
                background: #363636
                color: #fff
        
.enquiry_details
    display: flex
    flex-direction: column
    padding: 20px
    gap: 20px
    .metadata
        display: flex
        width: 50%
        justify-content: space-between
        // border: 1px solid red
        h4
            color: grey
            font-weight: 600
        span
            width: 50%
            padding: 5px
            color: #3636369a
            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px

.pdf
    width: 2vw
    text-decoration: none
    border: 1px solid red
    background: #73bce6
    font-size: 13px
    padding: 5px 10px
    font-weight: 500
    color: #fff
    text-transform: uppercase
    outline: none
    border: 1px solid #4fabe0
    cursor: pointer
    letter-spacing: 1px
    border-radius: 3px
    transition: 200ms all ease-in
    &:hover
        background: #fff
        color: #43a6e7